import React, { useState } from 'react';
import { connect } from 'react-redux';

import { login } from '../../api/user';
import { setCurrentUser } from '../../store/actions';

const Login = (props) => {
  const {
    currentUser,
    setCurrentUser,
  } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();

    const response = await login({ user: { username, password }})

    if (response.logged_in) {
      setCurrentUser(response.user);
    }
  };

  return (
    <div>
      <nav>
        <div className='header'>
          <div className='left'>
            <div className='logo'>
              <a to="/"><img src='/logo.svg' alt='logo'/></a>
              <a to="/">Веды</a>
            </div>
          </div>
        </div>
      </nav>
      <div className='login'>
        <div className='container'>
          <div className='title'>
            <img src='/profile.svg' alt="profile"/>
            <h3>Увайсьці</h3>
          </div>
          <form onSubmit={handleLogin}>
            <div className='form-control'>
              <label for='username'>
                Імя
              </label>
              <input
                id='username'
                placeholder='Імя'
                value={username}
                onChange={(e) => { setUsername(e.target.value) }}
                required
              />
            </div>
            <div className='form-control'>
              <label for='password'>
                Пароль
              </label>
              <input
                id='password'
                type='password'
                placeholder='Пароль'
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
                required
              />
            </div>
            <div className='form-control'>
              <input className='login-button' type='submit' value='Увайсьці'/>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
  })
)(Login);
