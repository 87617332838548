import axios from 'axios';

import { API_URL } from 'constants'

export const getUsers = () => {
  return axios.get(`${API_URL}/api/v1/users.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return null;
    });
}

export const checkLoggedIn = () => {
  return axios.get(`${API_URL}/logged_in`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return null;
    });
}

export const login = (params) => {
  return axios.post(`${API_URL}/sessions`, params, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return null;
    });
}

export const logout = () => {
  return axios.delete(`${API_URL}/logout`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return null;
    });
}
