import axios from 'axios';

import { API_URL } from 'constants'

export function getEconomicBranches() {
  return axios.get(`${API_URL}/api/v1/economic_branches.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function getEconomicBranch(id) {
  return axios.get(`${API_URL}/api/v1/economic_branches/${id}.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}
