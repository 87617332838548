import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getDocuments } from '../../api/document';
import { loadingStatuses } from '../../constants';

export const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(async () => {
    const documents = await getDocuments();

    setDocuments(documents);
    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <>
        <h1>Дакументы</h1>
        <div className="documents">
          {
            documents.map((document) => {
              const { id, title, description, type, link } = document;

              return (
                <div className="documents-document">
                  <Link to={`/documents/${id}`}><h3>{title}</h3></Link>
                  <p>{type}</p>
                  <p>{description}</p>
                </div>
              );
            })
          }
        </div>
      </>
    }
  }
}
