import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import cx from 'classnames';

import { getUsers } from '../../api/user';
import { getTasks } from '../../api/task';
import { getMeeting } from '../../api/meeting';

import { loadingStatuses } from '../../constants';
import { parseSeconds } from '../../utils';

export const Home = () => {
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [meeting, setMeeting] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  const [secondsToMeeting, setSecondsToMeeting] = useState(null);

  useEffect(async () => {
    const meeting = await getMeeting();

    if (meeting) {
      const secondsToMeeting = (new Date(meeting.date_at) - Date.now()) / 1000;

      if (secondsToMeeting > 0) {
        setMeeting(meeting);
        setSecondsToMeeting(secondsToMeeting);

        const interval = setInterval(
          () => {
            setSecondsToMeeting(secondsToMeeting => secondsToMeeting - 1);
            if (secondsToMeeting < 0) {
              clearInterval(interval);
            }
          }
        , 1000);
      }
    }

    let response = await getUsers();
    setUsers(response);

    response = await getTasks();
    setTasks(response);

    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  const secondsToMeetingLabel = parseSeconds(secondsToMeeting)
    .map((item) => (String(item).padStart(2, '0')))
    .join(' : ');

  return (
    <div className='home'>
      {
        loadingStatus === loadingStatuses.PENDING
          ? (
            <div className='spinner'>
              <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
            </div>
          ) : (
            <div>
              {
                meeting && (
                  <div className="home-meeting">
                    <h1>Тэма: {meeting.title}</h1>
                    <h3>Часу да сустрэчы: {secondsToMeetingLabel}</h3>
                  </div>
                )
              }
              <h3>Стан заданьняў</h3>
              <div
                className='home-tasks'
                style={{gridTemplateColumns: `repeat(${tasks.length + 1}, 1fr)`}}
              >
                <div className='profile task-item'></div>{
                  tasks.map(({id}) => (
                    <div className={cx('task-item')} key={`task-${id}`}>
                      <Link to={`/tasks/${id}`}>{id}</Link>
                    </div>
                  ))
                }
                {
                  users.map(({ id, username, color, last_seen_at, task_ids, avatar }) => {
                    const lessThenTenMinutes = (Date.now() - Date.parse(last_seen_at)) < 10 * 1000 * 60 ;

                    return (
                      <Fragment key={id}>
                        <div
                          key={`user-${id}`}
                          className={cx('task-item', 'profile', { active: lessThenTenMinutes })}
                          title={
                            `Апошняя актыўнасць: ${last_seen_at && (new Date(last_seen_at)).toLocaleString('by-BY', { hour12: false })}`
                          }
                        >
                          {
                            avatar
                              ? (
                                <span className='profile-avatar'>
                                  <img src={avatar}/>
                                </span>
                              )
                              : (
                                <span className='color' style={{backgroundColor: color}}>
                                </span>
                              )
                          }
                          <span className='username'>
                            {username}
                          </span>
                        </div>
                        {
                          tasks.map(({id: taskId}) => {
                            return (
                              <div key={`user-${id}-task-${taskId}`} className={cx('task-item')}>
                                {
                                  <>
                                    {
                                      task_ids.includes(taskId)
                                        ? <>
                                          <img src='checkBorder.svg'/>
                                          <img className='completed' src='check.svg'/>
                                        </>
                                        : <>
                                          <img className='incompleted' src='checkBorder.svg'/>
                                        </>
                                    }
                                  </>
                                }
                              </div>
                            );
                          })
                        }
                      </Fragment>
                    )
                  })
                }
              </div>
            </div>
          )
      }
      {/*<h1>Абярыце раздзел</h1>
      <div className='container'>
        <Link to="/events">
          Падзеі
        </Link>
        <Link to="/people">
          Асобы
        </Link>
        <Link to="/organizations">
          Арганізацыі
        </Link>
        <Link to="/documents">
          Дакументы
        </Link>
        <Link to="/tasks">
          Заданьні
        </Link>
      </div>*/}
    </div>
  );
}
