import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import ReactPlayer from 'react-player';

import { getPerson } from '../../api/person';
import { ActivePieDiagram } from '../diagrams';

export const Person = (props) => {
  const { id } = useParams();

  const [person, setPerson] = useState(null);

  useEffect(async () => {
    const person = await getPerson(id);

    setPerson(person);
  }, []);

  if (person) {
    const { name, description, organizations, documents, graphics } = person;

    return <>
      <div className='person'>
        <div className='person-title'>
          <h3>{name}</h3>
        </div>
        <p>
          {description?.split(/\n/).map((line, index) => {
            return <span className='new-line' key={index}>{line}</span>;
          })}
        </p>
        {
          organizations.length > 0 && (
            <>
              <h4>Арганізацыі</h4>
              <p className='person-organizations'>
                {
                  organizations.map((organization) => {
                    const { id, title } = organization;

                    return <Link to={`/organizations/${id}`}>{title}</Link>;
                  })
                }
              </p>
            </>
          )
        }
        {
          documents.length > 0 && (
            <>
              <h4>Дакументы</h4>
              <p className='person-documents'>
                {
                  documents.map((document) => {
                    const { id, title } = document;

                    return <Link to={`/documents/${id}`}>{title}</Link>;
                  })
                }
              </p>
            </>
          )
        }
        {
          graphics.length > 0 && <div>
            <h3>Графікі</h3>
            <div className='person__graphics'>
              {
                graphics.map(({ id, title, data }) => {
                  return <ActivePieDiagram id={id} title={title} data={data} />
                })
              }
            </div>
          </div>
        }
      </div>
    </>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}
