import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import ReactCountryFlag from "react-country-flag";

import { getPeople } from '../../api/person';
import { loadingStatuses } from '../../constants';

export const People = () => {
  const [people, setPeople] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);
  const [searchValue, setSearchValue] = useState('');

  useEffect(async () => {
    const people = await getPeople();

    setPeople(people);
    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      const filteredPeople = people.filter(({ name, short_description, description }) => {
        const searchFields = [name, short_description, description].join(' ').toLowerCase();

        return searchValue.toLowerCase().split(' ').every((value) => searchFields.includes(value));
      });

      return <>
        <h1>Асобы</h1>
        <input
          placeholder='Пошук'
          value={searchValue}
          onChange={(e) => (setSearchValue(e.target.value))}
        />
        <p>Колькасць: {filteredPeople.length}</p>
        <div className="people">
          {
            filteredPeople.map((person) => {
              const { id, name, short_description, organizations, country } = person;

              return <div className="people-person">
                <h3><Link to={`/people/${id}`}>{name}</Link> <ReactCountryFlag countryCode={country?.code} /></h3>
                <p>{short_description}</p>
                <p className='people-person-organizations'>
                  {
                    organizations.map((organization) => {
                      const { id, title } = organization;

                      return <Link to={`/organizations/${id}`}>{title}</Link>;
                    })
                  }
                </p>
              </div>
            })
          }
        </div>
      </>
    }
  }
}

// export People;
