import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getMeetings } from '../../api/meeting';
import { loadingStatuses } from '../../constants';

export const Meetings = () => {
  const [meetings, setMeetings] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(async () => {
    const meetings = await getMeetings();

    setMeetings(meetings);
    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <>
        <h1>Сустрэчы</h1>
        <div className='meetings'>
          {
            meetings.map((meeting, index) => {
              const { id, title, description } = meeting;

              return <div key={id} className='meetings-meeting'>
                <Link to={`/meetings/${id}`}><h3>{meetings.length - index}. {title}</h3></Link>
                <p>
                  {description?.split(/\n/).map((line, index) => {
                    return <span className='new-line' key={index}>{line}</span>;
                  })}
                </p>
              </div>
            })
          }
        </div>
      </>
    }
  }
}
