import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { SpinnerCircular } from 'spinners-react';
import ReactPlayer from 'react-player';
import cx from 'classnames';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import { getTask } from '../../api/task';
import { completeTask } from '../../api/usersTask';
import { setCurrentUser } from '../../store/actions';

const Task = (props) => {
  const { currentUser, setCurrentUser } = props;

  const { id } = useParams();

  const [task, setTask] = useState(null);

  useEffect(async () => {
    const task = await getTask(id);

    setTask(task);
  }, []);

  const handleComplite = async () => {
    const params = {
      users_task: {
        task_id: id
      }
    };
    const response = await completeTask(params);

    setCurrentUser({
      ...currentUser,
      task_ids: [
        ...currentUser.task_ids,
        +id
      ]
    });
  };

  if (task) {
    const { id, title, description, documents } = task;

    const isCompleted = currentUser.task_ids.includes(id);

    return <div className='task'>
      <div className='task-title'>
      <h1>Заданьне {id}</h1>
        {
          isCompleted && (
            <div className='task-status'>
              <img src='/checkBorder.svg'/>
              <img className='completed' src='/check.svg'/>
            </div>
          )
        }
      </div>
      <div className='task-content'>
        <div className='left'>
          <h3>{title}</h3>
          <p className='task-content-description'>
            {description?.split(/\n/).map((line, index) => {
              return <span className='new-line' key={index} dangerouslySetInnerHTML={{__html: line}}></span>;
            })}
          </p>
        </div>
        <div className='right'>
          {
            !isCompleted && (
              <a onClick={handleComplite} className={'complete'}>Выканана!</a>
            )
          }
        </div>
      </div>
      <div className='task-documents'>
        {
          documents.map((document) => {
            const { id, title, link, type } = document;

            return (type === 'Document::Video')
              ? (
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url={link}
                    width='100%'
                    height='100%'
                    controls
                  />
                </div>
              ) : (['Document::Book', 'Document::Document'].includes(type))
                ? (
                  <>
                    <div>
                      <a href={link} target='_blank'>Спампаваць</a>
                    </div>
                    <div className='document-book'>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: '750px' }}>
                          <Viewer fileUrl={link} />
                        </div>
                      </Worker>
                    </div>
                  </>
                ) : (
                  <div>
                    <a href={link} target='_blank'>Спасылка</a>
                  </div>
                )
          })
        }
      </div>
    </div>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
  })
)(Task);
