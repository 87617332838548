import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';

import { getTasks } from '../../api/task';
import { SpinnerCircular } from 'spinners-react';

import { loadingStatuses } from '../../constants';

const Tasks = (props) => {
  const { currentUser: { task_ids } } = props;

  const [tasks, setTasks] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(async () => {
    const tasks = await getTasks();

    setTasks(tasks);
    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      const incompletedTasks = tasks.filter(({ id }) => (!task_ids.includes(id)));
      const completedTasks = tasks.filter(({ id }) => (task_ids.includes(id)));

      if (incompletedTasks.length) {
        return (
          <>
            <h1>Заданьні</h1>
            <div className="tasks">
              {
                incompletedTasks.map((task) => {
                  const { id, title } = task;

                  return (
                    <div key={id} className="tasks-task">
                      <Link to={`/tasks/${id}`}>
                        <p>{id}. {title}</p>
                      </Link>
                    </div>
                  );
                })
              }
            </div>
            <div className="tasks">
              {
                completedTasks.map((task) => {
                  const { id, title } = task;

                  return (
                    <div key={id} className="tasks-task">
                      <Link to={`/tasks/${id}`} className={cx('completed')}>
                        <p>{id}. {title}</p>
                      </Link>
                    </div>
                  );
                })
              }
            </div>
          </>
        );
      } else {
        return (
          <div className='tasks'>
            <div className='tasks-content'>
              <h1>Усе заданьні зроблены!</h1>
            </div>
          </div>
        );
      }
    }
  }
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
  })
)(Tasks);
