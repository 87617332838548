import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getMeeting } from '../../api/meeting';

export const Meeting = (props) => {
  const { id } = useParams();

  const [meeting, setMeeting] = useState(null);
  const [name, setName] = useState(null);

  useEffect(async () => {
    const meeting = await getMeeting(id);

    setMeeting(meeting);
  }, []);

  if (meeting) {
    const { title, description, date_at, events, documents } = meeting;

    return <>
      <h1>Сустрэча</h1>
      <div className='meeting'>
        <h3>{title}</h3>
        <p>Дата: {date_at}</p>
        <p>
          {description?.split(/\n/).map((line, index) => {
            return <span className='new-line' key={index}>{line}</span>;
          })}
        </p>
        {
          events.length > 0 && (
            <>
              <h4>Падзеі</h4>
              <p className='meeting-events'>
                {
                  events.sort((a, b) => new Date(b.date_at) - new Date(a.date_at)).map((event) => {
                    const { id, title, date_at } = event;

                    return <Link to={`/events/${id}`}>
                      <span className='date'>
                        {(new Date(date_at)).toISOString().split('T')[0]}
                      </span>
                      {title}
                    </Link>;
                  })
                }
              </p>
            </>
          )
        }
        {
          documents.length > 0 && (
            <>
              <h4>Дакументы</h4>
              <p className='meeting-documents'>
                {
                  documents.sort((a, b) => new Date(b.date_at) - new Date(a.date_at)).map((document) => {
                    const { id, title, date_at } = document;

                    return <Link to={`/documents/${id}`}>
                      <span className='date'>
                        {(new Date(date_at)).toISOString().split('T')[0]}
                      </span>
                      {title}
                    </Link>;
                  })
                }
              </p>
            </>
          )
        }
      </div>
    </>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}
