import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getEvent } from '../../api/event';
import { ActivePieDiagram } from '../diagrams';

export const Event = (props) => {
  const { id } = useParams();

  const [event, setEvent] = useState(null);
  const [name, setName] = useState(null);

  useEffect(async () => {
    const event = await getEvent(id);

    setEvent(event);
  }, []);

  if (event) {
    const { title, description, date_at, documents, graphics } = event;

    return <>
      <h1>Падзея</h1>
      <div className='event'>
        <h3>{title}</h3>
        <p>Дата: {date_at}</p>
        <p>
          {description?.split(/\n/).map((line, index) => {
            return <span className='new-line' key={index}>{line}</span>;
          })}
        </p>
        {
          documents.length > 0 && (
            <>
              <h4>Дакументы</h4>
              <p className='event-documents'>
                {
                  documents.sort((a, b) => a.date_at - b.date_at).map((document) => {
                    const { id, title } = document;

                    return <Link to={`/documents/${id}`}>{title}</Link>;
                  })
                }
              </p>
            </>
          )
        }
        {
          graphics.length > 0 && <div>
            <h3>Графікі</h3>
            <div className='event__graphics'>
              {
                graphics.map(({ id, title, data }) => {
                  return <ActivePieDiagram id={id} title={title} data={data} />
                })
              }
            </div>
          </div>
        }
      </div>
    </>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}
