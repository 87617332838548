import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getOrganization } from '../../api/organization';
import { ActivePieDiagram, StackedAreaDiagram } from '../diagrams';
// import { createPerson } from '../../api/person';

const ENTER_KEY = 'Enter';

export const Organization = (props) => {
  const { id } = useParams();

  const [organization, setOrganization] = useState(null);
  const [name, setName] = useState(null);

  useEffect(async () => {
    const organization = await getOrganization(id);

    setOrganization(organization);
  }, [id]);

  // const handleAddPerson = async () => {
  //   const params = {
  //     name: name,
  //     people_organizations_attributes: [{ organization_id: id }],
  //   };

  //   const person = await createPerson(params);

  //   setOrganization({
  //     ...organization,
  //     people: [...organization.people, person],
  //   });

  //   setName('');
  // };

  if (organization) {
    const { title, description, people, council, organizations, organization: parentOrganization, graphics } = organization;

    return <>
      <h1>{title}</h1>
      <div className='organization'>
        <p>
          {description?.split(/\n/).map((line, index) => {
            return <span className='new-line' key={index} dangerouslySetInnerHTML={{__html:line}}></span>;
          })}
        </p>
        {/*<p>*/}
          {/*<input
            value={name}
            placeholder='Прозвішча Імя'
            onChange={(e) => { setName(e.target.value) }}
            onKeyDown={({ key }) => (key === ENTER_KEY) && handleAddPerson()}
          ></input>*/}
          {/*<button onClick={handleAddPerson}>Дадаць чалавека</button>*/}
        {/*</p>*/}
        {
          parentOrganization && <div className='organization-organization'>
            <h3>Уваходзіць у <Link key={id} to={`/organizations/${parentOrganization.id}`}><b>{parentOrganization.title}</b></Link></h3>
          </div>
        }
        {
          organizations.length > 0 && <div className='organization-organizations'>
            <h3>Структура</h3>
            {
              organizations.map(({ id, title }) => {
                return <div key={id}>
                  <Link to={`/organizations/${id}`}><h4>{title}</h4></Link>
                </div>
              })
            }
          </div>
        }
        {
          graphics.length > 0 && <div>
            <h3>Графікі</h3>
            <div className='organization__graphics'>
              {
                graphics.sort((a, b) => a.position - b.position).map(({ id, title, type, data }) => {
                  if (type === 'Graphic::StackedArea') {
                    return <StackedAreaDiagram key={id} title={title} data={data}/>
                  } else {
                    return <ActivePieDiagram key={id} title={title} data={data} />
                  }
                })
              }
            </div>
          </div>
        }
        <p className='organization-people'>
          {
            people.map((person) => {
              const { id, name } = person;

              return <Link to={`/people/${id}`}>{name}</Link>;
            })
          }
        </p>
        {
          council && (
            <div>
              <h3>Савет</h3>

              <div style={{width: 600, margin: '0 auto'}}>
                <svg id='image' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 910 500">
                  {
                    council.places.map(({ id, number, row, place }) => {
                      let x = place * 20 + 1;
                      let y = row * 22 + 1;

                      const option = council.options.find((option) => (option.numbers.includes(number)));

                      const color = option && option.color || 'transparent';

                      return <rect key={id} x={x} y={y} width={16} height={18} stroke='black' rx={3} fill={color}>
                        <title>{number}</title>
                      </rect>
                    })
                  }
                </svg>
              </div>
            </div>
          )
        }
      </div>
    </>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}
