import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components'
import cx from 'classnames'

import { getEvents, createEvent, updateEvent } from '../../api/event';
import { getStories, updateStory } from '../../api/story';
import { loadingStatuses } from '../../constants';

const Container = styled('div')`
  .Add {
    cursor: pointer;
  }

  .Stories-Container {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    margin-bottom: 16px;
  }

  .stories {
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
  }

  .Story {
    font-size: 20px;
    cursor: pointer;
    background-color: #f3f3f3;
    padding: 8px;

    &:hover {
      background-color: #EEF3FB;
    }

    &__Selected {
      background-color: #364B7E;
      color: #fff;

      &:hover {
        background-color: #566B9E;
      }
    }
  }

  .Event {
    display: flex;
    gap: 8px;

    input {
      border: none;
      border-bottom: 1px solid #ddd;
      font-size: 18px;
    }

    &-Date {
      width: 100px;
      font-weight: bold;
    }

    &-Title {
      flex-grow: 1;
      width: 50px;
    }

    &-Story-Selected:input {
      width: 13px;
    }

    &__Changed {
      input {
        color: #f80;
      }
    }
  }
`

export const Events = () => {
  const [events, setEvents] = useState([])
  const [stories, setStories] = useState([])
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING)
  const [enabledStoryFilter, setEnabledStoryFilter] = useState(false)

  useEffect(async () => {
    const events = await getEvents()
    const stories = await getStories()

    setEvents(events)
    setStories(stories)
    setLoadingStatus(loadingStatuses.LOADED)
  }, [])

  const onChange = (id, params) => {
    const event = events.find(({ id: eventId }) =>  eventId === id)
    const index = events.indexOf(event)

    setEvents([
      ...events.slice(0, index),
      {
        ...event,
        ...params
      },
      ...events.slice(index + 1)
    ])
  }

  const onSelectStory = (id) => {
    const story = stories.find(({ id: storyId }) =>  storyId === id)
    const { title, selected, event_ids } = story
    const index = stories.indexOf(story)

    setStories([
      ...stories.slice(0, index),
      {
        ...{
          ...story,
          selected: !selected
        }
      },
      ...stories.slice(index + 1)
    ])
  }

  const selectedEventIds = useMemo(
    () => stories.filter(({ selected }) => selected).flatMap(({ event_ids }) => event_ids),
    [stories]
  )

  const onUpdateEvent = (id, index) => {
    const event = events.find(({ id: eventId }) =>  eventId === id)
    const { title, date_at, changed } = event

    if (changed) {
      if (id) {
        const index = events.indexOf(event)

        updateEvent(id, { title, date_at }).then((event) => {
          setEvents([
            ...events.slice(0, index),
            {
              ...event
            },
            ...events.slice(index + 1)
          ])
        })
      } else {
        createEvent({ title, date_at }).then((event) => {
          setEvents([
            ...events.slice(0, index),
            {
              ...event
            },
            ...events.slice(index + 1)
          ])
        })
      }
    }
  }

  const reloadStory = (story) => {
    const index = stories.findIndex(({ id }) => id === story.id)

    setStories([
      ...stories.slice(0, index),
      {
        ...{
          ...stories[index],
          ...story,
        }
      },
      ...stories.slice(index + 1)
    ])
  }

  const onCheckEvent = (id, checked) => {
    stories.filter(({ selected }) => selected).forEach(({ id: storyId, event_ids }) => {
      if (checked) {
        updateStory(storyId, { event_ids: [...event_ids, id] }).then(reloadStory)
      } else {
        updateStory(storyId, { event_ids: event_ids.filter((event_id) => event_id != id)}).then(reloadStory)
      }
    })
  }

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return (
        <Container>
          <h1>Падзеі <span className="Add" onClick={() => setEvents((events) => [{}, ...events])}>+</span></h1>
          <div className='Stories-Container'>
            <div className='stories'>
              {
                stories.map(({ id, title, description, selected }, index) => {
                  return (
                    <div
                      key={index}
                      className={cx('Story', { Story__Selected: selected })}
                      onClick={() => onSelectStory(id)}
                    >
                      {
                        title
                      }
                    </div>
                  )
                })
              }
            </div>
            <div
              className={cx('Story Story-Filter', { Story__Selected: enabledStoryFilter })}
              onClick={() => setEnabledStoryFilter(!enabledStoryFilter)}
            >
              Фільтраваць
            </div>
          </div>
          <div className='events'>
            {
              events.filter(({ id }) => !enabledStoryFilter || selectedEventIds.includes(id)).map((event, index) => {
                const { id, title, description, date_at, changed } = event;

                return (
                  <div key={index} className={cx('Event', { Event__Changed: changed })}>
                    <input
                      className='Event-Date'
                      value={date_at || ''}
                      onChange={(e) => onChange(id, { date_at: e.target.value, changed: true })}
                      onBlur={(e) => onUpdateEvent(id, index)}
                    />
                    <input
                      className='Event-Title'
                      value={title || ''}
                      onChange={(e) => onChange(id, { title: e.target.value, changed: true })}
                      onBlur={(e) => onUpdateEvent(id, index)}
                    />
                    <input
                     className='Event-Story-Selected'
                     type='checkbox'
                     checked={selectedEventIds.includes(id)}
                     onChange={(e) => onCheckEvent(id, e.target.checked)}
                    />
                  </div>
                )
              })
            }
          </div>
        </Container>
      )
    }
  }
}

// export Events;
