import { SET_CURRENT_USER, SET_CSRF_TOKEN } from '../../constants';

export default (state = {}, action) => {
  switch(action.type) {
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
      }
    }
    case SET_CSRF_TOKEN: {
      return {
        ...state,
        csrfToken: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
