import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getOrganizations } from '../../api/organization';
import { loadingStatuses } from '../../constants';

export const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(async () => {
    const organizations = await getOrganizations();

    setOrganizations(organizations);
    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <>
        <h1>Арганізацыі</h1>
        <div className='organizations'>
          {
            organizations.map((organization) => {
              const { id, title, description, people } = organization;

              return <div className='organizations-organization'>
                <Link to={`/organizations/${id}`}><h3>{title}</h3></Link>
              </div>
            })
          }
        </div>
      </>
    }
  }
}
