import axios from 'axios';

import { API_URL } from 'constants'

export function getOrganizations() {
  return axios.get(`${API_URL}/api/v1/organizations.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function getOrganization(id) {
  return axios.get(`${API_URL}/api/v1/organizations/${id}.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}
