import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'constants'

export function getStories() {
  return axios.get(`${API_URL}/api/v1/stories.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function getStory(id) {
  return axios.get(`${API_URL}/api/v1/stories/${id}.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function createStory(params) {
  const token = Cookies.get('csrf_token');

  return axios.post(`${API_URL}/api/v1/stories.json`, { story: params }, { headers: { 'X-CSRF-TOKEN': token }, withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return response;
    })
}

export function updateStory(id, params) {
  const token = Cookies.get('csrf_token');

  return axios.put(`${API_URL}/api/v1/stories/${id}.json`, { story: params }, { headers: { 'X-CSRF-TOKEN': token }, withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return response;
    })
}
