import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import ReactPlayer from 'react-player';

import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import { getDocument } from '../../api/document';

export const Document = (props) => {
  const { id } = useParams();

  const [document, setDocument] = useState(null);

  useEffect(async () => {
    const document = await getDocument(id);

    setDocument(document);
  }, []);

  if (document) {
    const { title, description, type, link, date_at, people, events } = document;

    return <>
      <h1>Дакумент</h1>
      <div className='document'>
        <div className='document-description'>
          <h3>{title}</h3>
          <p>
            {description?.split(/\n/).map((line, index) => {
              return <span className='new-line' key={index}>{line}</span>;
            })}
          </p>
          {
            date_at && (
              <p>
                Дата: {date_at}
              </p>
            )
          }
        </div>
        {
          people.length > 0 && (
            <>
              <h4>Асобы</h4>
              <p className='document-people'>
                {
                  people.map((person) => {
                    const { id, name } = person;

                    return <Link to={`/people/${id}`}>{name}</Link>;
                  })
                }
              </p>
            </>
          )
        }
        {
          events.length > 0 && (
            <>
              <h4>Падзеі</h4>
              <p className='document-events'>
                {
                  events.sort((a, b) => a.date_at - b.date_at).map((event) => {
                    const { id, title } = event;

                    return <Link to={`/events/${id}`}>{title}</Link>;
                  })
                }
              </p>
            </>
          )
        }
        <div className='document-content'>
          {
            (type === 'Document::Video')
              ? (
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url={link}
                    width='100%'
                    height='100%'
                    controls
                  />
                </div>
              ) : (['Document::Book', 'Document::Document'].includes(type))
                ? (
                  <>
                    <div>
                      <a href={link} target='_blank'>Спампаваць</a>
                    </div>
                    <div className='document-book'>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div style={{ height: '750px' }}>
                          <Viewer fileUrl={link} />
                        </div>
                      </Worker>
                    </div>
                  </>
                ) : (
                  <div>
                    <a href={link} target='_blank'>Спасылка</a>
                  </div>
                )
          }
        </div>
      </div>
    </>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}
