import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'constants'

export function getPeople() {
  return axios.get(`${API_URL}/api/v1/people.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function getPerson(id) {
  return axios.get(`${API_URL}/api/v1/people/${id}.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function createPerson(params) {
  const token = Cookies.get('csrf_token');

  return axios.post(`${API_URL}/api/v1/people.json`, params, { headers: { 'X-CSRF-TOKEN': token }, withCredentials: true})
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response)
    });
}
