import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import { getEconomicBranches } from '../../api/economicBranch';
import { loadingStatuses } from '../../constants';

export const EconomicBranches = () => {
  const [economicBranches, setEconomicBranches] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  useEffect(async () => {
    const economicBranches = await getEconomicBranches();

    setEconomicBranches(economicBranches);
    setLoadingStatus(loadingStatuses.LOADED);
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      return <>
        <h1>Эканоміка</h1>
        <div className="economicBranches">
          {
            economicBranches.sort((a, b) => b.portion - a.portion).map((economicBranch) => {
              const { id, title, portion } = economicBranch;

              return (
                <div key={id} className="economicBranches-economicBranch">
                  <Link to={`/economicBranches/${id}`}><h3>{title}</h3></Link>
                </div>
              );
            })
          }
        </div>
      </>
    }
  }
}
