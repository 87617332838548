import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink as Link,
} from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';

import {
  Home,
  Login,
  Events,
  Event,
  People,
  Person,
  Organizations,
  Organization,
  Documents,
  Document,
  Tasks,
  Task,
  Meetings,
  Meeting,
  EconomicBranches,
  EconomicBranch,
} from './pages';

import { generateCSRFToken } from '../store/thunks';
import './App.scss';

import { checkLoggedIn, logout } from '../api/user';
import { setCurrentUser } from '../store/actions';

import { loadingStatuses } from '../constants';

const App = (props) => {
  const {
    currentUser,
    setCurrentUser,
    // csrfToken,
    // generateCSRFToken,
  } = props;

  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  const handleLogout = async () => {
    const response = await logout();

    if (response.logged_out) {
      setCurrentUser(null);
    }
  }

  useEffect(async () => {
    const response = await checkLoggedIn();

    if (response.logged_in) {
      setCurrentUser(JSON.parse(response.user));
    }

    setLoadingStatus(loadingStatuses.LOADED);
    // setUser(user);
    //  generateCSRFToken();
  }, []);

  switch(loadingStatus) {
    case loadingStatuses.PENDING: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      );
    }
    case loadingStatuses.LOADED: {
      if (currentUser) {
        const { avatar, color, username } = currentUser;

        return (
          <Router>
            <div>
              <nav>
                <div className='header'>
                  <div className='left'>
                    <div className='logo'>
                      <Link to="/"><img src='/logo.svg' alt='logo'/></Link>
                      <Link to="/">Веды</Link>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='header-links'>
                      <Link to="/events">Падзеі</Link>
                      <Link to="/people">Асобы</Link>
                      <Link to="/organizations">Арганізацыі</Link>
                      <Link to="/economicBranches">Эканоміка</Link>
                      <Link to="/documents">Дакументы</Link>
                      <Link to="/tasks">Заданьні</Link>
                      <Link to="/meetings">Сустрэчы</Link>
                    </div>
                    <div className='dropdown'>
                      <div className='profile'>
                        {
                          avatar
                            ? (
                              <span className='profile-avatar'>
                                <img src={avatar}/>
                              </span>
                            )
                            : (
                              <span className='color' style={{backgroundColor: color}}>
                              </span>
                            )
                        }
                        <span className='username'>
                          {username}
                        </span>
                      </div>
                      <div className='dropdown-content'>
                        <div className='dropdown-content-links'>
                          {/*<Link to="/organizations">Профіль</Link>
                          <Link to="/organizations">Налады&nbsp;акаўнта</Link>*/}
                          <a onClick={handleLogout}>Выйсці</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
              <div className='container'>
                <Routes>
                  <Route path="/events" element={<Events />} />
                  <Route path="/events/:id" element={<Event />} />
                  <Route path="/economicBranches" element={<EconomicBranches />} />
                  <Route path="/economicBranches/:id" element={<EconomicBranch />} />
                  <Route path="/people" element={<People />} />
                  <Route path="/people/:id" element={<Person />} />
                  <Route path="/organizations" element={<Organizations />} />
                  <Route path="/organizations/:id" element={<Organization />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/documents/:id" element={<Document />} />
                  <Route path="/tasks" element={<Tasks />} />
                  <Route path="/tasks/:id" element={<Task />} />
                  <Route path="/meetings" element={<Meetings />} />
                  <Route path="/meetings/:id" element={<Meeting />} />
                  <Route path="/" element={<Home />} />
                </Routes>
              </div>
            </div>
          </Router>
        );
      } else {
        return (
          <Login/>
        );
      }
    }
  }
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
    // csrfToken: state.main.csrfToken,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
    // generateCSRFToken: () => dispatch(generateCSRFToken()),
  })
)(App);
