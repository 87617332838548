import axios from 'axios';

import { API_URL } from 'constants'

export function getMeetings() {
  return axios.get(`${API_URL}/api/v1/meetings.json`, { withCredentials: true })
    .then(response => response.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function getMeeting(id) {
  if (id) {
    return axios.get(`${API_URL}/api/v1/meetings/${id}.json`, { withCredentials: true })
      .then(response => response.data)
      .catch((response) => {
        console.log(response);

        return [];
      })
  } else {
    return axios.get(`${API_URL}/api/v1/meetings_latest.json`, { withCredentials: true })
      .then(response => response.data)
      .catch((response) => {
        console.log(response);

        return [];
      })
  }
}
    // return {
    //   date: new Date('2021.11.30 20:00'),
    //   topic: 'Выбары 2006 года + выбарчыя нормы',
    // }
