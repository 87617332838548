import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import ReactCountryFlag from "react-country-flag";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import { getEconomicBranch } from '../../api/economicBranch';

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#0088FE', '#00C49F', '#FFBB28', '#FF8042','#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

export const EconomicBranch = (props) => {
  const { id } = useParams();

  const [economicBranch, setEconomicBranch] = useState(null);

  useEffect(async () => {
    const economicBranch = await getEconomicBranch(id);

    setEconomicBranch(economicBranch);
  }, [id]);

  if (economicBranch) {
    const { title, description, economic_branches, economic_branches_people, economic_branches_organizations } = economicBranch;

    const data = economic_branches.map(({ title, portion }) => ({ name: title, value: +portion }));
    const colors = economic_branches.map(({ color }) => color);

    // console.log(data)

    return <>
      <h1>{title}</h1>
      <div className='economicBranch'>
        <p>
          {description?.split(/\n/).map((line, index) => {
            return <span className='new-line' key={index} dangerouslySetInnerHTML={{__html:line}}></span>;
          })}
        </p>
        {
          economic_branches.length > 0 && <div>
            <h3>Структура</h3>
            <div className='economicBranch__branches'>
              <div>
                {
                   economic_branches.sort((a, b) => b.portion - a.portion).map((economicBranch) => {
                    const { id, title, portion, color } = economicBranch;

                    return <div key={id}>
                      <span className='economicBranch__branch__color' style={{backgroundColor: color}}></span>
                      <Link to={`/economicBranches/${id}`}>{title} ({portion})</Link>
                    </div>
                  })
                }
              </div>
              <div>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={500} height={500}>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        }
        <div className='economicBranch-people'>
          <h3>Асобы</h3>
          {
            economic_branches_people.map((economic_branches_person) => {
              const { person: { id, name, country }, description } = economic_branches_person;

              return <div key={id}>
                <Link to={`/people/${id}`}>{name} {description && `(${description})`} <ReactCountryFlag countryCode={country?.code} /></Link>
              </div>
            })
          }
        </div>
        <div className='economicBranch-organizations'>
          <h3>Арганізацыі</h3>
          {
            economic_branches_organizations.map((economic_branches_organization) => {
              const { organization: { id, title, people }, description } = economic_branches_organization;

              return <div key={id}>
                <Link to={`/organizations/${id}`}><h4>{title} {description && `(${description})`}</h4></Link>
                {
                  people.map((person) => {
                    const { id, name } = person;

                    return <div key={id}>
                      <Link to={`/people/${id}`}>{name}</Link>
                    </div>
                  })
                }
              </div>
            })
          }
        </div>
      </div>
    </>
  } else {
    return <div className='spinner'>
      <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
    </div>
  }
}
